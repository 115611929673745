import { UpdateIdentityBody } from "@ory/client"
import axios from "axios"

import APIConfig from "./config"
import { Partner, PartnerSummary, User, UserKratosIdentity } from "./types"

const portalApi = axios.create({
  baseURL: `${APIConfig.portalApi}/portal-api/v1`,
  withCredentials: true,
})

export const listPartners = async ({
  offset,
  limit,
}: {
  offset: number
  limit: number
}): Promise<PartnerSummary[]> => {
  const { data } = await portalApi.get(
    `/partners?offset=${offset}&limit=${limit}`,
  )
  return data
}

export const listPartnerIds = async (): Promise<number[]> => {
  const { data } = await portalApi.get("/partner_ids")
  return data
}

export const createPartner = async (payload: any): Promise<Partner> => {
  const { data } = await portalApi.post("/partners", payload)
  return data
}

export const getPartner = async (
  partnerId: string,
): Promise<PartnerSummary> => {
  const { data } = await portalApi.get(`/partners/${partnerId}`)
  return data
}

export const updatePartner = async ({
  partnerId,
  payload,
}: {
  partnerId: string
  payload: any
}): Promise<Partner> => {
  const { data } = await portalApi.put(`/partners/${partnerId}`, payload)
  return data
}

export const deletePartner = async (partnerId: string): Promise<void> => {
  await portalApi.delete(`/partners/${partnerId}`)
}

export const whoAmI = async (): Promise<User> => {
  const { data } = await portalApi.get("/users/me")
  return data
}

export const createUser = async (payload: any): Promise<any> => {
  const { data } = await portalApi.post("/users", payload)
  return data
}

export const listUsers = async (partnerId?: string): Promise<UserKratosIdentity[]> => {
  const { data } = await portalApi.get(
    partnerId ? `/users?partnerId=${partnerId}` : "/users",
  )
  return data
}

export const getUser = async (userId: string): Promise<any> => {
  const { data } = await portalApi.get(`/users/${userId}`)
  return data
}

export const updateUser = async ({
  userId,
  payload,
}: {
  userId: string
  payload: UpdateIdentityBody
}): Promise<any> => {
  const { data } = await portalApi.put(`/users/${userId}`, payload)
  return data
}

export const deleteUser = async (userId: string): Promise<any> => {
  const { data } = await portalApi.delete(`/users/${userId}`)
  return data
}

export const getApiKeys = async (): Promise<any> => {
  const { data } = await portalApi.get(`/api-keys`)
  return data
}

export const createAPIKey = async (payload: any): Promise<any> => {
  const { data } = await portalApi.post("/api-keys", payload)
  return data
}

export const deleteApiKey = async (apiKeyId: string): Promise<any> => {
  const { data } = await portalApi.delete(`/api-keys/${apiKeyId}`)
  return data
}
